import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'

import { injected } from '../wallet/connect'

const chain = {
  chainId: "0x89",
  chainName: "Polygon Mainnet",
  rpcUrls: ["https://polygon-rpc.com"],
  blockExplorerUrls: ['https://polygonscan.com/'],
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
}

const checkChainId = async () => {
  const { ethereum } = window;
  console.log(ethereum.chainId)
  if (ethereum.chainId !== process.env.REACT_APP_RPC_NETWORK_ID) {
    const params = [{ chainId: `${process.env.REACT_APP_RPC_NETWORK_ID}` }];
    await ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [chain], // chainId must be in hexadecimal numbers
    });
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: params
    })
  }
};

export function ConnectWallet() {
  const { activate, active, account, library, chainId, error } = useWeb3React();
  async function connect() {
    try {
      await checkChainId();
      await activate(injected);
    } catch (e) {
      console.log(e)
    }
  }
  return [active, account, connect]
}

export function DisconnectWallet(setAuth) {
  const { deactivate } = useWeb3React()
  async function disconnect() {
    try {
      deactivate()
      localStorage.setItem('token', null)
      setAuth(false)
    } catch (ex) {
      console.log(ex)
    }
  }

  // useEffect(() => {
  //   disconnect()
  // }, []) // intentionally only running on mount (make sure it's only mounted once :))

  return [disconnect]
}

export function useEagerConnect() {
  const { activate, active } = useWeb3React()

  const [tried, setTried] = useState(false)

  useEffect(() => {
    injected.isAuthorized().then(async (isAuthorized) => {
      if (isAuthorized) {
        await checkChainId()
        await activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        setTried(true)
      }
    })
  }, []) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true)
    }
  }, [tried, active])

  return tried
}
